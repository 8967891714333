import {BillableVisit} from "../../types/BillableVisit";
import {useTranslation} from "react-i18next";
import {
    DataGrid,
    GridColDef,
    GridPaginationModel,
    GridRowParams,
    GridSortModel,
    GridValueFormatterParams
} from "@mui/x-data-grid";
import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {CustomNoRowsOverlay} from "../Shared/Tables/CustomNoRowsOverlay";
import {GridApiCommunity} from "@mui/x-data-grid/internals";
import {CurrentCustomerContext} from "../../contexts/CurrentCustomerContext";
import dayjs from "dayjs";
import WarningIcon from "@mui/icons-material/Warning";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import UpdateBillableVisitModal from "./Modals/UpdateBillableVisitModal";
import { BillableVisitType } from "../../types/enums/BillableVisitType";
import { PreRegistrationStatusType } from "../../types/enums/PreRegistrationType";
import { boxStyle } from "../Shared/Tables/listStyles";
import { useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "../../helpers/LanguageHelper";

interface BillingTableProps {
    billableVisits: BillableVisit[];
    loading: boolean;
    setRowChanged: (changed: (prevState: any) => boolean) => void;
    gridApiRef: React.MutableRefObject<GridApiCommunity>
    totalElements?: number;
    paginationModel: GridPaginationModel;
    onPaginationModelChange: (newModel: GridPaginationModel) => void;
    sortModel: GridSortModel;
    onSortModelChange: (newSortModel: GridSortModel) => void;
    showingCurrentMonth? : boolean;
}

const BillingTable = (({billableVisits, loading, setRowChanged, gridApiRef, totalElements, paginationModel, onPaginationModelChange, sortModel, onSortModelChange, showingCurrentMonth=false}: BillingTableProps) => {
    const {t, i18n} = useTranslation();
    const {currentCustomer} = useContext(CurrentCustomerContext);
    const [selectedRow, setSelectedRow] = useState<BillableVisit | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setRows(billableVisits.map(generateRows));
    }, [billableVisits]);

    const timeColumnFormatter = (params: GridValueFormatterParams):string => {
        return dayjs(params.value).locale(getCurrentLanguage(i18n.language)).format('D MMM HH:mm');
    };

    const columns: GridColDef[] = [
        {field: 'timeStamp', valueFormatter: timeColumnFormatter, headerName: t("Tid"), minWidth: 50, maxWidth: 150, flex: 1},
        {field: 'facility', headerName: t("Anlaggning"), sortable: false, minWidth: 50, maxWidth: 200, flex: 1, disableColumnMenu: true},
        {field: 'link', headerName: "", width: 50, sortable: false, disableColumnMenu: true, hideSortIcons: true,
            renderCell: (params) => {
            return (
            <Tooltip title={t("Öppna i") + ' ' + t("Fordon_Historik")} sx={{cursor: "pointer"}}>
                <LocalShippingIcon fontSize="small" />
            </Tooltip>);
        }},
        {field: 'regnumberClear', headerName: t("Regnr_shortest"), minWidth: 50, maxWidth: 100, flex: 1, disableColumnMenu: true},
        {field: 'company', headerName: t("Företagsnamn"), valueGetter: (params) => t(params.value), minWidth: 50, maxWidth: 300, flex: 1, disableColumnMenu: true},
        {field: 'visitType', headerName: t("Visit_Type"), sortable: false, minWidth: 50, maxWidth: 200, flex: 1, disableColumnMenu: true, valueGetter: (params) => t(params.value)},
        {field: 'comment', headerName: t("Kommentar"), minWidth: 50, maxWidth: 300, flex:1, disableColumnMenu:true},
        {field: 'reference', headerName: t("Referens"), minWidth: 50, maxWidth: 100, flex:1, disableColumnMenu:true}, 
        {field: 'icons', headerName: "", width: 50, disableColumnMenu: true, hideSortIcons: true,
            renderCell: (params) => {
                const {exception} = params.row;
                return (
                    <Grid container justifyContent={"flex-end"}>
                        {exception && <Tooltip title={exception}><WarningIcon sx={{ml: "5px"}}/></Tooltip>}
                    </Grid>
                );
            }
        }
    ];

    const generateRows = (billableVisit: BillableVisit): any[] => {
        const {vehicleData} = billableVisit;
        const rows: any[] = [{
            id: billableVisit.id,
            timeStamp: dayjs(vehicleData.sensorTime).format('YYYY-MM-DD HH:mm'),
            facility: currentCustomer.facilities.find(facility => facility.id === billableVisit.facilityId)?.name ?? null,
            regnumberClear: vehicleData.regnumberClear,
            company: vehicleData.ownerName,
            visitType: billableVisit.type,
            comment: billableVisit.comment,
            reference: billableVisit.reference,
            exception: billableVisit.watchListTitle,
            preRegistration: billableVisit.preRegistration
        }];

        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    };

    const [rows, setRows] = useState(() => billableVisits.map(generateRows));

    const handleCellClick = (params: any) => {
        if(params.field !== 'link' && params.field !== 'regnumberClear') {
            const selectedVisit = billableVisits.find(visit => visit.id === params.row.id);
            if (selectedVisit) {
                setSelectedRow(selectedVisit);
                setIsModalOpen(true);
            }
        }
        else if(params.field === 'link') {
            navigate(`/vehiclehistory/${currentCustomer.id}?regnumber=${params.row.regnumberClear}`);
        }
    };

    const nonPayableVisitRow = (params: GridRowParams) => {
        const visitType = params.row.visitType;
        const preRegistration = params.row.preRegistration;
        if(visitType === BillableVisitType.PRE_REGISTERED && preRegistration && preRegistration.status === PreRegistrationStatusType.INVOICE_AFTER_REVIEW ) {
            return `custom-payable-item`;
        }
        else if (visitType !== BillableVisitType.INVOICE) {
            return `custom-nonPayable-item`;
        }
        else {
            return `custom-payable-item`;
        }
    }


    return (
        <Box sx={boxStyle}>
            <Paper>
                <DataGrid
                    sx={{height: '55vh', maxHeight: '70vh', width: '100%', '--DataGrid-overlayHeight': '300px'}}
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    getRowId={(row: any) => row.id}
                    getRowClassName={nonPayableVisitRow}
                    paginationMode='server'
                    rowCount={totalElements}
                    paginationModel={paginationModel}
                    onPaginationModelChange={onPaginationModelChange}
                    sortingMode='server'
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    sortingOrder={['desc', 'asc']}
                    pageSizeOptions={[10, 25, 50, 100]}
                    apiRef={gridApiRef}
                    hideFooterSelectedRowCount={true}
                    onCellClick={handleCellClick}
                    hideFooter={false}
                    disableRowSelectionOnClick={true}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        pagination: {
                            showFirstButton: true,
                            showLastButton: true,
                        },
                    }}
                />
            </Paper>
            <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                <Grid container spacing={2}>
                    <Grid item xs="auto" sx={{ marginLeft: { xs: 0, md: "auto"}}}>
                        <UpdateBillableVisitModal
                            open={isModalOpen}
                            handleClose={() => setIsModalOpen(false)}
                            setRowChanged={setRowChanged}
                            billableVisit={selectedRow as BillableVisit}
                            showingCurrentMonth={showingCurrentMonth}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
});

export default BillingTable;