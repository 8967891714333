import CommentIcon from '@mui/icons-material/Comment';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import ListItem from "@mui/material/ListItem";
import {useTranslation} from "react-i18next";
import {VisitComponent} from "./Visit/VisitComponent";
import {Stack} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from "dayjs";
import 'dayjs/locale/sv'
import 'dayjs/locale/en';
import {BillableVisitType} from "../../types/enums/BillableVisitType";
import {PreRegistrationStatusType} from "../../types/enums/PreRegistrationType";

export const VehicleListItem = ({open, onOpen, billableVisit, settings}) => {
    const [displayVisitData, setDisplayVisitData] = useState(billableVisit);
    const {t} = useTranslation();
    const {vehicleData} = billableVisit;

    function formatRegNumber(regNumber: string): String {
        return regNumber.substring(0, 3) + " " + regNumber.substring(3)
    }

    function formatSensorTime(sensorTime: number): String {
        const datetime = dayjs(sensorTime);
        const currentDate = dayjs();

        const isToday = datetime.isSame(currentDate, 'day');

        if (isToday) {
            return datetime.format('HH:mm');
        } else {
            const formattedDate = datetime.format('YYYY-MM-DD');
            const formattedTime = datetime.format('HH:mm');
            return `${formattedDate} ${formattedTime}`;
        }
    }

    const close = (item:any) => {
        if(item) {
            setDisplayVisitData({...item, reference:item.reference,comment:item.comment});
        }

        onOpen("")
    }

    const back = (item:any) => {
        if(item) {
            setDisplayVisitData({...item, reference:item.reference,comment:item.comment});
        }
    }

    const handleReRoute = (item:any) => {
        if(item) {
            setDisplayVisitData({...item});
            open=false;
        }
    }

    function setColorBasedOnVisit(){
        switch (displayVisitData.type){
            case BillableVisitType.INVOICE:
                return 'white'
            case BillableVisitType.EXEMPT:
                return 'lightyellow'
            case BillableVisitType.FREE_VEHICLE:
            case BillableVisitType.FREE_ORGANIZATION:
                return 'lightgreen'
            case BillableVisitType.FOREIGN:
                return 'lightblue'
            case BillableVisitType.REVISIT:
                return 'linear-gradient(90deg, rgba(215,213,213,1) 10%, rgba(255,255,255,1) 50%, rgba(215,213,213,1) 90%)'
            case BillableVisitType.PRE_REGISTERED:
                return setColorBasedOnPreRegistration();
        }
    }

    function setColorBasedOnPreRegistration(){
        if(displayVisitData.preRegistration) {
            switch (displayVisitData.preRegistration.status) {
                case PreRegistrationStatusType.APPROVED:
                case PreRegistrationStatusType.APPROVED_AFTER_REVIEW:
                case PreRegistrationStatusType.INVOICE_AFTER_REVIEW:
                case PreRegistrationStatusType.TO_REVIEW:
                    return '#9fede8'
                case PreRegistrationStatusType.EXPIRED:
                    return 'grey'
                case PreRegistrationStatusType.UNKNOWN:
                    return 'grey'
                default:
                    return 'grey'
            }
        }
    }

    function setBorderColorBasedOnPreRegistration(){
        if(displayVisitData.preRegistration) {
            switch (displayVisitData.preRegistration.status) {
                case PreRegistrationStatusType.APPROVED:
                case PreRegistrationStatusType.APPROVED_AFTER_REVIEW:
                case PreRegistrationStatusType.EXPIRED:
                    return '#05a18f'
                case PreRegistrationStatusType.INVOICE_AFTER_REVIEW:
                    return '#cf0a9a'
                case PreRegistrationStatusType.TO_REVIEW:
                    return 'orange'
                case PreRegistrationStatusType.UNKNOWN:
                    return 'black'
                default:
                    return 'black'
            }
        }
        return 'black'
    }

    function defaultDesign(){
        return (
            <>

                <Grid
                    container
                    item
                    xs={4}
                >
                    <Grid item>
                        <Typography variant="h6">
                            {formatRegNumber(vehicleData.regnumberClear)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    direction="column"
                    justifyContent="right"
                    xs={1}>
                    <Grid container direction="row" justifyContent="center" sx={{ textAlign: "center" }}  alignItems="center" minWidth="100px">
                        {displayVisitData.watchListTitle ? (
                            <>
                                <InfoOutlinedIcon fontSize="large" color="info"/>
                                <Grid sx={{ textAlign: "center" }} justifyContent="center"  width="100%">
                                    <Typography justifyContent="center" variant="caption" noWrap component="p">
                                        {t(displayVisitData.watchListTitle)}
                                    </Typography>

                                </Grid>
                            </>
                        ):(
                            <>
                                {displayVisitData.comment && <CommentIcon fontSize="medium"/>}
                                {displayVisitData.reference && <AddToHomeScreenIcon fontSize="medium"/>}
                            </>
                        )

                        }
                    </Grid>
                </Grid>
                <Grid container item direction="column" alignItems="flex-end" xs={7}>
                    <Grid  sx={{height:"30%", minWidth:'30%'}} alignItems="top" item>
                        <Typography variant="body2">
                            {formatSensorTime(displayVisitData.vehicleData.sensorTime)}
                        </Typography>
                        {settings.find(setting => setting.name === "SENSOR_NAME" && setting.show) && (
                        <Typography variant='body2'>
                            {displayVisitData.vehicleData.sensor}
                        </Typography>
                        )}
                    </Grid>

                    <Grid   sx={{maxHeight:"70%", minWidth:'50px',maxWidth:'50%'}} alignItems="bottom" item>
                        {((displayVisitData.type === BillableVisitType.INVOICE) || (displayVisitData.type === BillableVisitType.PRE_REGISTERED)) && displayVisitData.payableAmount>0 &&
                            <>
                                <Stack alignItems="bottom">
                                    <Typography variant="h6" >
                                        {t(displayVisitData.payableAmount)}
                                    </Typography>
                                    <Typography variant="caption" >
                                        {t("exkl_moms")}
                                    </Typography>
                                </Stack>
                            </>
                        }

                        {!displayVisitData.payableAmount &&
                            <>
                                <Typography variant="body2" align={'left'}>
                                    {t(displayVisitData.type)}
                                </Typography>
                                {displayVisitData.type === BillableVisitType.FOREIGN && displayVisitData.foreignHandled &&
                                    <Typography variant="body2" align={'left'}>
                                        {t("Hanterad")}
                                    </Typography>
                                }
                                {displayVisitData.type === BillableVisitType.EXEMPT && displayVisitData.exemption &&
                                    <Typography variant="body2" align={'left'}>
                                        {t(displayVisitData.exemption.reason)}
                                    </Typography>
                                }
                            </>
                        }
                    </Grid>

                    {displayVisitData.type === BillableVisitType.PRE_REGISTERED && !displayVisitData.payableAmount &&
                        <>
                            <Grid sx={{height:"10%",width:"70%"}}  item xs zeroMinWidth>
                                <>
                                    <Stack alignItems="bottom">
                                        <Typography variant="body2" noWrap component="p">
                                            {t(displayVisitData.preRegistration.status)}
                                        </Typography>
                                    </Stack>
                                </>
                            </Grid>
                        </>
                    }
                    {displayVisitData.type === BillableVisitType.FREE_ORGANIZATION && displayVisitData.vehicleData.ownerName &&
                        <>
                            <Grid sx={{height:"10%", width: billableVisit.watchListTitle? "50%" : "100%"}}  item xs zeroMinWidth>
                                <>
                                    <Stack alignItems="bottom">
                                        <Typography variant="caption" noWrap component="p">
                                            {t(displayVisitData.vehicleData.ownerName) + "(" + t(displayVisitData.vehicleData.owner)+")"}
                                        </Typography>
                                    </Stack>
                                </>
                            </Grid>
                        </>
                    }
                </Grid>
            </>
        )
    }

    return (
        <ListItem
            key={billableVisit.clientRef}
            sx={{
                p: 2,
                margin: 'auto',
                border: '2px solid ' + setBorderColorBasedOnPreRegistration(),
                borderRadius: 3,
                boxShadow: 3,
                background:setColorBasedOnVisit(),
            }}
            onClick={() => {onOpen(billableVisit.clientRef)}}
        >

            <Grid container minHeight={"80px"} spacing={1} alignItems="center" >
                {defaultDesign()}
                <VisitComponent onHistoryClick={handleReRoute} dataBillableVisit={displayVisitData} hidden={!open} close={close} back={back}/>
            </Grid>
        </ListItem>
    )
}
