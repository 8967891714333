import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { Navigate } from "react-router-dom";
import { useCurrentUser } from "../contexts/CurrentUserContext";
import { useEffect, useState } from "react";
import { ErrorResponse } from "../api/apiUtils";
import { fetchLoggedInUser } from "../api/UserAPI";
import { isEmptyArray } from "../helpers/IsEmptyHelpers";
import { allAuthorizedUserRoles, flAdminRole } from "../types/CurrentUserRoles";

export function Home() {
  const isAuthenticated = useIsAuthenticated();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ErrorResponse[]>([]);
  const {currentUser, setCurrentUser} = useCurrentUser();

  useEffect(() => {
    if(isAuthenticated){
        getUser();
    }
  }, [isAuthenticated])


const getUser = async () => {
  try {
    setLoading(true);
    const {data, errors} = await fetchLoggedInUser();
    if(isEmptyArray(errors)){
        setCurrentUser({
            name: data.username,
            fullName: data.fullname,
            customerId: data.customerId,
            groups: data.userGroups, 
            roles: data.userRoles,
            isAuthorized: data.userRoles.some(r => allAuthorizedUserRoles.map(role => role.id).includes(r.id)),
            isAdmin: data.userRoles.map(role => role.id).includes(flAdminRole.id)
        })
    }
    else {
      setCurrentUser({isAuthorized: false, isAdmin: false, roles: [], groups: []});
    }
      setErrors(errors);
    } finally {
      setLoading(false);
    }
  };

  return (
      <>
          <AuthenticatedTemplate>
            {!loading && currentUser && !currentUser.isAuthorized && <Typography variant="h6" align="center">{t("Ej_behörig")}</Typography>}
            {currentUser && currentUser.isAdmin && <Navigate to="/customers"/>}
            {currentUser && currentUser.isAuthorized && !currentUser.isAdmin && <Navigate to={`/customers/${currentUser.customerId}`}/>}
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Typography variant="h6" align="center">{t("Välkommen_text_inlogg")}</Typography>
          </UnauthenticatedTemplate>
      </>
  );
}
