import React, {useState} from "react";
import Button from "@mui/material/Button";
import {Autocomplete, Chip, OutlinedTextFieldProps, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import { useAsync } from "react-use";
import { User } from "../../../types/User";
import { customerAdminRole, nonAdminRoles, UserRole } from "../../../types/CurrentUserRoles";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";
import { UserGroup } from "../../../types/CurrentUserGroups";
import { useCurrentCustomer } from "../../../contexts/CurrentCustomerContext";

const UpdateUserModal = ({open, handleClose, handleConfirmChanges, data}:
    {
        open: boolean,
        handleClose:any,
        handleConfirmChanges:any,
        data: User
    }
) => {
    const {t} = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden)
    const [infoData, setInfoData] = useState({});
    const [dirtyForm, setDirtyForm] = useState(false);

    const [roleValues, setRoleValues] = useState<UserRole[]>([]);
    const [roleInputValue, setRoleInputValue] = useState("");
    const [groupValues, setGroupValues] = useState<UserGroup[]>([]);
    const [groupInputValue, setGroupInputValue] = useState("");

    const {currentUser} = useCurrentUser();
    const {currentCustomer} = useCurrentCustomer();
    const [selectableRoleOptions, setSelectableRoleOptions] = useState<UserRole[]>([])
    const [fixedRoleOptions, setFixedRoleOptions] = useState<UserRole[]>([]);

    const [selectableGroupOptions, setSelectableGroupOptions] = useState<UserGroup[]>([])
    const [formData, setFormData] = useState<{
        username:string,
        fullname:string,
        active: boolean,
        roles: number[],
        groups: number[]
    }>({
        username: '',
        fullname: '',
        active: true,
        roles: [],
        groups: []
    });

    const [errors, setErrors] = useState({
        roles: {value: false, message: ''}
    });

    const intialize = useAsync(async () => {
            setStartData();
    }, [data]);

    const setStartData = () => {
        if(data) {
            formData.username = data.username;
            formData.fullname = data.fullname;
            formData.active = data.active;
            formData.roles = data.userRoles.map(ur => ur.id);
            formData.groups = data.userGroups.map(ug => ug.id);

            const currentSelectableOptions = currentUser.isAdmin? [customerAdminRole, ...nonAdminRoles]: nonAdminRoles;
            setSelectableRoleOptions(currentSelectableOptions);
            setFixedRoleOptions(data.userRoles
                .filter(r => !currentSelectableOptions.map(o => o.id).includes(r.id)));

            setSelectableGroupOptions(currentCustomer.customerGroups);
            setRoleValues(data.userRoles);
            setGroupValues(data.userGroups);
        }
    }

    const validateForm = () => {
        const rolesValid = formData.roles.length > 0;

        setErrors({
            roles: {value: !rolesValid, message: rolesValid? '' : "Roll_krävs"}
        });

        return rolesValid;
    };

    const handleRoleSelectChange = (newValue: UserRole[]) => {
        setDirtyForm(true);
        setRoleValues([...fixedRoleOptions, ...newValue.filter((value) => !fixedRoleOptions.includes(value))]);
        if(newValue){
            setInfoData({...infoData, [t("Roll")]: newValue.map(v => t(v.name)).toString()})
        }
        setFormData({...formData, roles: newValue ? newValue.map(r => r.id): [] });
    }

    const handleGroupSelectChange = (newValue: UserGroup[]) => {
        setDirtyForm(true);
        setGroupValues(newValue);
        if(newValue) {
            setInfoData({...infoData, [t("Grupp")]: newValue.map(v => v.name).toString()})
        }
        setFormData({...formData, groups: newValue ? newValue.map(g => g.id): []});
    }

    const handleSubmit = () => {
        if (validateForm()) {
            toggleHidden();
            confirmationDialog.handleOpen();
        }
    };

    const handleAgree = () => {
        handleConfirmChanges(formData);
        toggleHidden();
        setDirtyForm(false);
    }

    const clearFormData = () => {
        setDirtyForm(false);
        setFormData({
            username: '',
            fullname: '',
            active: true,
            roles: [],
            groups: []
            });
        setInfoData({});
        setGroupValues([]);
        setGroupInputValue("");
        setRoleValues([]);
        setRoleInputValue("");

        setErrors({
            roles: {value: false, message: ''}
        });
    };

    return (
        <>
            <Dialog
                open={open}
                hidden={hidden}
                onClose={(e) => {clearFormData(); handleClose();}}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
            >
                <DialogTitle variant="h5">{t("Redigera_Roller_Grupper")}</DialogTitle>
                <DialogContent sx={{minWidth: '10vw', minHeight: '20vh'}}>
                    <Grid container rowSpacing={3} columnSpacing={2} sx={{pt:1}}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">{data.fullname} ({data.username})</Typography>
                        </Grid>
                        <Grid item xs={12}>
                                <Autocomplete
                                    selectOnFocus
                                    multiple
                                    clearOnBlur
                                    size="small"
                                    id="role-select"
                                    limitTags={2}
                                    options={[...fixedRoleOptions, ...selectableRoleOptions]}
                                    getOptionLabel={(option) => t(option.name)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                        <Chip
                                            variant="outlined"
                                            label={t(option.name)}
                                            size="small"
                                            {...getTagProps({ index })}
                                            disabled={fixedRoleOptions.includes(option)}
                                        />
                                        ))
                                    }
                                    value={roleValues}
                                    onChange={(e, newValue) => handleRoleSelectChange(newValue)}
                                    inputValue={roleInputValue}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onInputChange={(e, newInputValue) => setRoleInputValue(newInputValue)}
                                    renderInput={(params) => (
                                        <TextField 
                                        {...params as unknown as OutlinedTextFieldProps} 
                                        variant="outlined"
                                        error={errors.roles.value}
                                        label={t("Välj_Roller")}
                                        helperText={errors.roles.value ? t(errors.roles.message): ''}
                                        />
                                    )}
                                    sx={{
                                        background: "#FFF",
                                        borderRadius: "4px",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    selectOnFocus
                                    multiple
                                    clearOnBlur
                                    size="small"
                                    id="group-select"
                                    limitTags={2}
                                    options={selectableGroupOptions}
                                    getOptionLabel={(option) => t(option.name)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                        <Chip
                                            variant="outlined"
                                            label={t(option.name)}
                                            size="small"
                                            {...getTagProps({ index })}
                                        />
                                        ))
                                    }
                                    value={groupValues}
                                    onChange={(e, newValue) => handleGroupSelectChange(newValue)}
                                    inputValue={groupInputValue}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onInputChange={(e, newInputValue) => setGroupInputValue(newInputValue)}
                                    renderInput={(params) => (
                                        <TextField 
                                        {...params as unknown as OutlinedTextFieldProps} 
                                        variant="outlined"
                                        label={t("Välj_Grupper")}
                                        />
                                    )}
                                    sx={{
                                        background: "#FFF",
                                        borderRadius: "4px",
                                    }}
                                />
                            </Grid>
                        </Grid>
                        </DialogContent>
                <DialogActions sx={{m: 1}}>
                    <Button disabled={!dirtyForm} color="inherit" variant="outlined" type="submit">{t("Uppdatera")}</Button>
                    <Button variant="outlined" color="error" onClick={() => {clearFormData(); handleClose()}}>{t("Avbryt")}</Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog
                open={confirmationDialog.open}
                handleClose={() => confirmationDialog.handleClose(toggleHidden)}
                handleAgree={() => confirmationDialog.handleAgree(handleAgree)}
                title={t("Spara_Användare")}
                subtitle={t("Följande uppgifter kommer att ändras")}
                content={infoData}
                errors={null}
                list={[]}
                active={false}
            />
        </>
    );
};
export default UpdateUserModal;