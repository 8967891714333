import { createContext, useContext, useEffect, useState } from "react";
import {UserRole} from "../types/CurrentUserRoles";
import {UserGroup} from "../types/CurrentUserGroups";

const initUser: {
    currentUser: {name:string, fullName: string, customerId: number, groups: UserGroup[], roles: UserRole[], isAdmin:boolean, isAuthorized:boolean},
    setCurrentUser: (data:any) => void
} = {
    currentUser: {name: '', fullName: '', customerId: -1, groups:[], roles:[], isAdmin: false, isAuthorized: false},
    setCurrentUser: (data) => {}
};

export const CurrentUserContext = createContext(initUser);

const getInitialState = () => {
    const currentUser = localStorage.getItem("currentUser");
    return currentUser ? JSON.parse(currentUser) : initUser;
}

const CurrentUserContextProvider = (props) => {
    const [currentUser, setCurrentUser] = useState(getInitialState);

    useEffect(() => {
        localStorage.setItem("currentUser", JSON.stringify(currentUser));
    },[currentUser]);

    return (
        <CurrentUserContext.Provider value={{currentUser, setCurrentUser}}>
            {props.children}
        </CurrentUserContext.Provider>
    );
};

export const useCurrentUser = () => useContext(CurrentUserContext);

export default CurrentUserContextProvider;