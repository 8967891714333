import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {styled} from "@mui/material/styles";
import Link from "@mui/material/Link";
import {Link as RouterLink} from "react-router-dom";
import {AppSettingsAlt, Help, Logout, Report} from "@mui/icons-material";
import { Typography } from '@mui/material';
import { useCurrentCustomer } from '../../contexts/CurrentCustomerContext';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { useMsal } from '@azure/msal-react';

const MobileDrawerHeader = styled("div")(({theme, hidden}) => ({
    display: hidden ? "none" : "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

interface CustomMobileDrawerProps {
    open: boolean;
    hidden: boolean;
    handleDrawerClose: () => void;
}

const CustomMobileDrawer: React.FC<CustomMobileDrawerProps> = ({open, hidden, handleDrawerClose,}) => {
    const {t} = useTranslation();
    const {currentCustomer, setCurrentCustomer} = useCurrentCustomer();
    const {currentUser, setCurrentUser} = useCurrentUser();
    const {instance} = useMsal();
    
    const handleLogout = () => {
        setCurrentCustomer({})
        setCurrentUser({});
            instance.logoutRedirect({
                postLogoutRedirectUri: "/realtimeview"
            });

    };

    const drawerItems = [
        {
            text: "Inställningar",
            icon: <AppSettingsAlt/>,
            url: "/realtimeview/settings",
            action: () => {}
        },
        {
            text: "Rapportera ett problem",
            icon: <Report/>,
            url: "/realtimeview/report",
            action: () => {}
        },
        {
            text: "Hjälp",
            icon: <Help/>,
            url: "/realtimeview/help",
            action: () => {}
        },
        {
            text: "Logga ut",
            icon: <Logout/>,
            url: "/realtimeview",
            action: handleLogout
        },
    ];


    const DrawerList = (
        <Box sx={{width: 250}} role="presentation" onClick={handleDrawerClose}>
            {drawerItems.map((item, index) => (
                <React.Fragment key={index}>
                    <ListItem key={t(item.text)} disablePadding sx={{display: "block"}}>
                        <Link
                            sx={{textDecoration: "none"}}
                            component={RouterLink}
                            to={item.url}>
                            <ListItemButton
                                onClick={item.action}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                    }}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={t(item.text)} sx={{opacity: open ? 1 : 0}}/>
                            </ListItemButton>
                        </Link>
                    </ListItem>
                    {index === 1 && <Divider/>}
                </React.Fragment>
            ))}
        </Box>
    )

    return (
        <div hidden={hidden}>
            <Drawer open={open} onClose={handleDrawerClose} anchor={"right"}>
                <MobileDrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                        <ChevronRightIcon/>
                    </IconButton>
                </MobileDrawerHeader>
                <Typography variant="subtitle2" align='center'>{currentUser.fullName}</Typography>
                <Divider/>
                {DrawerList}
            </Drawer>
        </div>
    );
}
export default CustomMobileDrawer;