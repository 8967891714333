import * as React from "react";
import {useState} from "react";
import FacilitiesTable from "./FacilitiesTable";
import {useAsync} from "react-use";
import {updateFacilityServices} from "../../api/ServiceAPI";
import {updateFacility, fetchFacilityServices} from "../../api/FacilitiesAPI";
import {fetchCustomerFacilities} from "../../api/CustomerAPI";
import {Facility, toFacility} from "../../types/Facility";
import {useTranslation} from "react-i18next";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import {ServiceType} from "../../types/enums/ServiceType";
import {toService} from "../../types/Service";
import {ErrorResponse} from "../../api/apiUtils";
import {isEmptyArray} from "../../helpers/IsEmptyHelpers";
import Box from "@mui/material/Box";
import { useCurrentCustomer } from "../../contexts/CurrentCustomerContext";

let changeData: {[key: string]: string} = {}

let updateData ={
    name: "",
}

let updateDataService: {[key: string]: string} = {}

let addData:{rows:any[],dateToUpdate:string, serviceType:string, statusText: string} ={
    rows:[],
    dateToUpdate:"",
    serviceType: "",
    statusText:"startas",
}

let backgroundData:{rows:any[]} ={
    rows:[]
}

export default function Facilities({update}) {
    const [facilities, setFacilities] = useState<Facility[]>([]);
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [facilityId, setFacilityId] = React.useState("");
    const [errors, setErrors] = useState<ErrorResponse[]>([]);
    const [buttonActive, setButtonActive] = React.useState(false);
    const [statusDate, setStatusDate] = React.useState("startas");
    const [loading, setLoading] = useState(false);
    const {currentCustomer} = useCurrentCustomer();
    let id = currentCustomer.id;
    const {
        error
    } = useAsync(async () => {
        if(statusDate !== "startas"){
            addData.statusText = "avslutas";
        }
        if(!buttonActive){
            await getFacilitiesAndServices();
        }

        addData.statusText = statusDate;
    }, [id, statusDate,update]);

    const getFacilitiesAndServices = async () => {
        if (id) {
            try {
                setLoading(true);
                const { data: facilityData, errors: facilityErrors } = await fetchCustomerFacilities(id.toString());
                let combinedErrors = [...facilityErrors];

                for (const arrayItem of facilityData) {
                    const { data: servicesData, errors: servicesErrors } = await fetchFacilityServices(arrayItem.id);
                    servicesData ? arrayItem.services = servicesData : combinedErrors = [...combinedErrors, ...servicesErrors]
                }

                const allFacilities: any[] = [...facilityData];
                setFacilities(allFacilities);
                setErrors(combinedErrors);
            } finally {
                setLoading(false)
            }
        }
    }

    const saveFacility = async () => {
        if (facilityId) {
            const {data, errors} = await updateFacility(facilityId, updateData);
            if (isEmptyArray(errors)) {
                setOpenDialog(false);
                await getFacilitiesAndServices();
            } else {
                setErrors(errors)
            }
        }
        return null;
    }

    const saveService = async (item:any) => {
        if (item.serviceId) {
            const {data, errors} = await updateFacilityServices(item.serviceId, item.updateData);
            if (isEmptyArray(errors)) {
                setOpenDialog(false);
                await getFacilitiesAndServices();
            } else {
                setErrors(errors)
            }
        }
    }

    function setDates(item:any):any{
        let changeDate = new Date(addData.dateToUpdate)
        let facility = facilities.map(toFacility).find(i => i.id === item.facilityId);
        let measuring:any = facility?.services.map(toService).find(i => i.type === ServiceType.MEASURING);
        let demo:any = facility?.services.map(toService).find(i => i.type === ServiceType.DEMO_AUTO_INVOICING);
        let company:any = facility?.services.map(toService).find(i => i.type === ServiceType.COMPANY_AUTO_INVOICING);
        let sole:any = facility?.services.map(toService).find(i => i.type === ServiceType.SOLE_TRADER_AUTO_INVOICING);

        updateDataService ={};
        backgroundData.rows=[];

        if(statusDate === "startas"){

            updateDataService.startDate=addData.dateToUpdate;

            if(addData.serviceType === ServiceType.MEASURING){
                backgroundData.rows.push({serviceId: demo.id, updateData:{startDate:updateDataService.startDate, endDate: updateDataService.endDate}})
            }
            if(addData.serviceType === ServiceType.COMPANY_AUTO_INVOICING){
                let updateDate = new Date(changeDate.getFullYear(),changeDate.getMonth(),changeDate.getDay()-1).toISOString().substring(0, 10);
                backgroundData.rows.push({serviceId: demo.id, updateData:{startDate:demo.startDate,endDate: updateDate}})
            }

            item.updateData = {startDate:updateDataService.startDate, endDate: updateDataService.endDate}

        }else{
            updateDataService.startDate=addData.dateToUpdate;
            updateDataService.endDate=addData.dateToUpdate;

            if(addData.serviceType === ServiceType.MEASURING){

                updateDataService.startDate=measuring.startDate;
                if(demo.startDate && demo.active){
                    backgroundData.rows.push({serviceId: demo.id, updateData:{startDate:demo.startDate,endDate: updateDataService.endDate}})
                }
                if(company.startDate && company.active){
                    backgroundData.rows.push({serviceId: company.id, updateData:{startDate:company.startDate,endDate: updateDataService.endDate}})
                }
                if(sole.startDate && sole.active){
                    backgroundData.rows.push({serviceId: sole.id, updateData:{startDate:sole.startDate,endDate: updateDataService.endDate}})
                }
            }

            if(addData.serviceType === ServiceType.COMPANY_AUTO_INVOICING){
                if(sole.startDate){
                    backgroundData.rows.push({serviceId: sole.id, updateData:{startDate:sole.startDate,endDate: updateDataService.endDate}})
                }
            }

            item.updateData = {startDate:updateDataService.startDate, endDate: updateDataService.endDate}
        }
    }

    function validateDates(item:any):any{
        let oldRow = facilities.map(toFacility).find(i => i.id === item.facilityId);

        if(addData.serviceType !== ServiceType.MEASURING){
            let measuring:any = oldRow?.services.find(i => i.type === ServiceType.MEASURING);
            if(statusDate === "startas"){
                let itemStartDate = Date.parse(addData.dateToUpdate);
                let oldStartDate = Date.parse(measuring.startDate);
                if((itemStartDate<oldStartDate) || isNaN(oldStartDate)){
                    throw new Error("Kan ej understiga mätning")
                }
            }else {
                let itemEndDate = Date.parse(addData.dateToUpdate);
                let oldEndDate = Date.parse(measuring.endDate);
                if((itemEndDate>oldEndDate) && !isNaN(oldEndDate)){
                    throw new Error("Kan ej överstiga mätning")
                }
            }
        }
    }

    const onSave = async () => {
        if(addData.rows.length<1){
            await saveFacility();
        }else{
            try {
                addData.rows.some(item => {
                        validateDates(item);
                        setDates(item);
                    }
                )

                backgroundData.rows.push(...addData.rows)
                backgroundData.rows.map(item => saveService(item))
            }catch (error:any){
                let errors:any[] = [];
                errors.push(error)
                setErrors(errors);
            }
        }
    }

    const onNameChange = (newRow:any, oldRow:any) =>
    {
        clearDialogData()
        if(JSON.stringify(newRow) !== JSON.stringify(oldRow)){
            setFacilityId(newRow.id)
            changeData.namn= newRow.name;
            updateData.name = newRow.name;
            setButtonActive(false)
            setOpenDialog(true)
        }

        setButtonActive(false)
    }

    const onDateChange =  (services:any, bulkEdit:any) => {
        clearDialogData();
        addData = {...services};

        if(!bulkEdit) {
            if (JSON.stringify(addData.dateToUpdate) !== JSON.stringify(addData.rows[0].startDate)) {
                setOpenDialog(true)
                setButtonActive(true)
            }
        }else {
            if(addData.rows.length === 0){
                setButtonActive(false)
            }else {
                setStatusDate(addData.statusText);
                setOpenDialog(true)
                setButtonActive(true)
            }
        }
    }

    const clearDialogData = () =>{
        changeData={};
        addData.rows = [];
    }

    const onClose = () =>
    {
        setOpenDialog(false)
        setErrors([]);
    }

    return (
        <>
            {error && <div>{t("Något gick fel", { error })}</div>}

            {!error && (
            <Box sx={{maxHeight: '70%'}}>
                <FacilitiesTable
                    onDateChange={onDateChange}
                    onNameChange={onNameChange}
                    facilities={facilities}
                    loading={loading}
                />
                <ConfirmationDialog
                    open={openDialog}
                    title={t("Ändra anläggningsuppgifter")}
                    subtitle={t("Följande uppgifter kommer att ändras") + ":"}
                    content={changeData}
                    handleAgree={onSave}
                    handleClose= {() =>onClose()}
                    errors={errors}
                    list={addData}
                    active={buttonActive}
                />

            </Box>
            )}
        </>

    );
}
