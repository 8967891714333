import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
    FormControl,
    Select,
    MenuItem,
    Typography,
    Grid,
    InputLabel,
    SelectChangeEvent, useTheme,
} from "@mui/material";
import {BillableVisit} from "../../../types/BillableVisit";
import dayjs from "dayjs";
import 'dayjs/locale/sv';
import 'dayjs/locale/en';
import {CurrentCustomerContext} from "../../../contexts/CurrentCustomerContext";
import {useTranslation} from "react-i18next";
import {AnimatePresence, motion} from "framer-motion";
import Box from "@mui/material/Box";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../../hooks/useConfirmationDialog";
import {ErrorResponse} from "../../../api/apiUtils";
import WarningIcon from "@mui/icons-material/Warning";
import {getCurrentLanguage} from "../../../helpers/LanguageHelper";
import i18n from "../../../i18n";
import {
    updateExempt,
    updateForeignHandled,
    updateInvoice,
    updateVisitComment,
    updateVisitReference
} from "../../../api/BillableVisitsAPI";
import {isEmptyArray} from "../../../helpers/IsEmptyHelpers";
import ChangeHistoryModal from "./ChangeHistoryModal";
import {ExemptStatsComponent} from "../../Shared/ExemptStatsComponent";
import {BillableVisitType} from "../../../types/enums/BillableVisitType";
import {ExemptionType} from "../../../types/enums/ExemptionType";

interface UpdateBillableVisitModalProps {
    open: boolean;
    handleClose: () => void;
    setRowChanged: (changed: (prevState: any) => boolean) => void;
    // handleConfirmChanges: any,
    billableVisit: BillableVisit;
    showingCurrentMonth?: boolean;
}

const initialFormData = (billableVisit?: BillableVisit) => ({
    id: billableVisit?.id.toString() || '',
    type: billableVisit?.type || null,
    reference: billableVisit?.reference || '',
    comment: billableVisit?.comment || '',
    exempt: billableVisit?.type === 'EXEMPT',
    exemptionReason: billableVisit?.exemption?.reason || '',
    foreignHandled: billableVisit?.foreignHandled || false,
});

type FormData = ReturnType<typeof initialFormData>;

const initialValidationErrors = {
    comment: { value: false, message: '' },
    exemptionReason: { value: false, message: '' }
};

const CompanyInfo = ({ billableVisit, t }) => (
    <>
        <Typography variant="subtitle2" align="center">{t("Foretag")}</Typography>
        <Typography variant="body2" align="center">{t(billableVisit.vehicleData.ownerName)}</Typography>
        {billableVisit.vehicleData.owner && billableVisit.vehicleData.owner.length > 0 &&
        <Typography variant="body2" align="center">{"(" + billableVisit.vehicleData.owner + ")"}</Typography>
        }
    </>
);

const ForeignHandledCheckbox = ({ checked, onChange, t, disabled = false }) => (
    <Box display="flex" justifyContent="center" width="100%">
        <FormControlLabel
            control={
                <Checkbox
                    value={checked}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                />
            }
            label={<Typography variant="subtitle2">{t("Hanterad")}</Typography>}
        />
    </Box>
);

const WarningText = ({ watchListTitle, watchListComment, t}) => (
    <Grid container>
        <Grid item xs={12}>
            <Box display="flex" alignItems="center">
                <WarningIcon sx={{mr: 1}}/>
                <Typography variant="h6">
                    {watchListTitle}
                </Typography>
                </Box>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="subtitle1">
                {watchListComment}
            </Typography>
        </Grid>
    </Grid>
);

const UpdateBillableVisitModal = ({open, handleClose, setRowChanged, billableVisit, showingCurrentMonth=false}: UpdateBillableVisitModalProps) => {
    const {t} = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const {currentCustomer} = useContext(CurrentCustomerContext);
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden)
    const [changeHistoryOpen, setChangeHistoryOpen] = useState(false);
    const [exemptCheckboxChecked, setExemptCheckboxChecked] = useState(false);
    const [foreignCheckboxChecked, setForeignCheckboxChecked] = useState(false);
    const [renderConditionalFields, setRenderConditionalFields] = useState(false);
    const [renderConditionalCheckbox, setRenderConditionalCheckbox] = useState(false);
    const [exemptVisit, setExemptVisit] = useState(false);
    const [commentCharCount, setCommentCharCount] = useState(0);
    const maxCommentChars = 250;
    const [infoData, setInfoData] = useState<{ [key: string]: string }>({});
    const [formData, setFormData] = useState(initialFormData(billableVisit));
    const [validationErrors, setValidationErrors] = useState(initialValidationErrors);
    const [responseErrors, setResponseErrors] = useState<ErrorResponse[]>([]);
    const theme = useTheme();

    useEffect(() => {
        setHidden(false);
    }, [open]);

    useEffect(() => {
        if (billableVisit) {
            const initialData = initialFormData(billableVisit);
            setFormData(initialData);
            setExemptCheckboxChecked(initialData.exempt);
            setForeignCheckboxChecked(initialData.foreignHandled);
            setRenderConditionalFields(![BillableVisitType.FOREIGN, BillableVisitType.FREE_VEHICLE, BillableVisitType.FREE_ORGANIZATION].includes(billableVisit.type));
            setRenderConditionalCheckbox([BillableVisitType.INVOICE, BillableVisitType.EXEMPT].includes(billableVisit.type));
            setExemptVisit([BillableVisitType.EXEMPT].includes(billableVisit.type));
        }
    }, [billableVisit]);

    if (!billableVisit) return null;

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({...formData, [e.target.id]: e.target.value});
        setInfoData({...infoData, [e.target.name]: e.target.value});
        if (e.target.id === 'comment') {
            setValidationErrors({
                ...validationErrors,
                comment: {value: (e.target.value.length > maxCommentChars), message: 'Kommentar får inte vara för lång'}
            });
            setCommentCharCount(e.target.value.length);
        }
    };

    const handleExemptChange = () => {
        if (!exemptCheckboxChecked) {
            // If the checkbox is unchecked, reset the exemptionReason field
            removeAttributeByValue(formData.exemptionReason);
            setFormData(prevFormData => ({...prevFormData, exempt: true, exemptionReason: initialFormData(billableVisit).exemptionReason}));
        } else {
            // If the checkbox is checked, only update the exempt field
            setFormData(prevFormData => ({...prevFormData, exempt: false}));
            setInfoData({...infoData, "Visit_Type": BillableVisitType.INVOICE});
        }
        setExemptCheckboxChecked(!exemptCheckboxChecked);
    };

    const handleSelectChange = (e: SelectChangeEvent) => {
        setFormData(prevFormData => ({...prevFormData, exemptionReason: e.target.value}));
        setInfoData({...infoData, [e.target.name]: e.target.value});
    };

    const handleForeignCheckboxChecked = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData(prevFormData => ({...prevFormData, foreignHandled: e.target.checked}));
        setForeignCheckboxChecked(e.target.checked);
    };

    const handleSubmit = () => {
        if (validateForm()) {
            toggleHidden();
            confirmationDialog.handleOpen();
        }
    };

    const handleAgree = () => {
        handleConfirmChanges(formData);
    }

    const toggleChangeHistoryModal = () => {
        setChangeHistoryOpen(prev => !prev);
    }

    const handleConfirmChanges = async (updatedItem: FormData) => {
        let hasChange = false;

        if (updatedItem.reference !== initialFormData(billableVisit).reference) {
            const {errors} = await updateVisitReference(updatedItem.id,encodeURIComponent(updatedItem.comment), encodeURIComponent(updatedItem.reference));
            responseErrors.push(...errors)
            hasChange = true;
        }

        if (updatedItem.comment !== initialFormData(billableVisit).comment) {
            const {errors} = await updateVisitComment(updatedItem.id,encodeURIComponent(updatedItem.comment));
            responseErrors.push(...errors)
            hasChange = true;
        }

        if (updatedItem.exempt !== initialFormData(billableVisit).exempt && !updatedItem.exempt) {
            const {errors} = await updateInvoice(updatedItem.id, encodeURIComponent(updatedItem.reference), encodeURIComponent(updatedItem.comment));
            responseErrors.push(...errors);
            hasChange = true
        }

        if (updatedItem.exemptionReason !== initialFormData(billableVisit).exemptionReason) {
            const {errors} = await updateExempt(updatedItem.id, encodeURIComponent(updatedItem.exemptionReason), encodeURIComponent(updatedItem.comment));
            responseErrors.push(...errors);
            hasChange = true
        }

        if (updatedItem.foreignHandled !== initialFormData(billableVisit).foreignHandled) {
            const {errors} = await updateForeignHandled(updatedItem.id, updatedItem.foreignHandled, encodeURIComponent(updatedItem.comment));
            responseErrors.push(...errors);
            hasChange = true
        }

        if(hasChange && isEmptyArray(responseErrors)) {
            setRowChanged(prevState => !prevState);
            resetFormData();
            handleClose();
        }
    };

    const validateForm = () => {
        const commentValid = formData.comment.length <= maxCommentChars;
        const exemptionValid = !exemptCheckboxChecked || (exemptCheckboxChecked && formData.exemptionReason.length > 0);
        setValidationErrors({
            comment: {value: !commentValid, message: commentValid ? '' : t('För lång')},
            exemptionReason: {value: !exemptionValid, message: exemptionValid ? '' : t('En anledning måste väljas')}
        });
        return commentValid && exemptionValid;
    };

    const resetFormData = () => {
        const initialData = initialFormData(billableVisit);
        setFormData(initialData);
        setExemptCheckboxChecked(initialData.exempt);
        setForeignCheckboxChecked(initialData.foreignHandled);
        setValidationErrors(initialValidationErrors);
        setResponseErrors([]);
        setInfoData({});
        setCommentCharCount(0);
    };

    const compareData = () => {
        return (JSON.stringify(initialFormData(billableVisit)) !== JSON.stringify(formData)) && (!exemptCheckboxChecked || (exemptCheckboxChecked && formData.exemptionReason));
    }

    const removeAttributeByValue = (valueToRemove: string) => {
        const filteredEntries = Object.entries(infoData).filter(([value]) => value !== valueToRemove);
        const newInfoData = Object.fromEntries(filteredEntries);
        setInfoData(newInfoData);
    };

    return (
        <>
            <Dialog
                open={open}
                hidden={hidden}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    },
                }}
                fullWidth>
                <DialogTitle>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h5">
                                {dayjs(billableVisit.vehicleData.sensorTime).locale(getCurrentLanguage(i18n.language)).format('D MMMM HH:mm:ss')}
                            </Typography>
                            <Typography variant="subtitle1">
                                {currentCustomer.facilities.find(facility => facility.id === billableVisit.facilityId)?.name} - {billableVisit.vehicleData.sensor}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" align="right">
                                {t(billableVisit.type)}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent sx={{minWidth: '10vw', minHeight: '20vh'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={renderConditionalFields ? 3 : 6}>
                            <Typography variant="subtitle2" align="center">{t("Fordon")}</Typography>
                            <Typography variant="body2"
                                        align="center">{billableVisit.vehicleData.regnumberClear}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {billableVisit.type !== BillableVisitType.FOREIGN ? (
                                <CompanyInfo billableVisit={billableVisit} t={t}/>
                            ) : (
                                <ForeignHandledCheckbox
                                    checked={foreignCheckboxChecked}
                                    onChange={handleForeignCheckboxChecked}
                                    t={t}
                                    disabled={!showingCurrentMonth}
                                />
                            )}
                        </Grid>
                        {renderConditionalFields && (
                            <Grid item xs={3}>
                                <Typography variant="subtitle2" align="center">{t("Avgift")}</Typography>
                                <Typography variant="body2" align="center">{billableVisit.payableAmount + ' ' + t("kr exkl moms")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                    {billableVisit.watchListTitle && billableVisit.watchListComment && (
                        <WarningText
                            watchListTitle={billableVisit.watchListTitle}
                            watchListComment={billableVisit.watchListComment}
                            t={t}
                        />
                    )}
                    {renderConditionalFields && (
                        <TextField
                            id={"reference"}
                            name={t("Referens")}
                            label={t("Referens")}
                            value={formData.reference}
                            onChange={handleInputChange}
                            disabled={exemptCheckboxChecked || !showingCurrentMonth}
                            variant={exemptCheckboxChecked ? "filled" : "outlined"}
                            fullWidth
                            margin="normal"/>
                    )}
                    <TextField
                        id="comment"
                        name={t("Kommentar")}
                        label={t("Kommentar")}
                        size="small"
                        value={formData.comment}
                        onChange={handleInputChange}
                        disabled={!showingCurrentMonth}
                        fullWidth
                        margin="normal"
                        error={validationErrors.comment.value}
                        helperText={showingCurrentMonth && (commentCharCount + '/' + maxCommentChars + ' ' + (validationErrors.comment.value ? t(validationErrors.comment.message) : ''))}
                        autoComplete="off"
                        multiline
                        rows={4}/>
                    {renderConditionalCheckbox && showingCurrentMonth && (
                        <Box sx={{overflow: 'hidden'}}>
                            <Grid mt={1}></Grid>
                            <Grid container>
                                <ExemptStatsComponent regNumber={billableVisit.vehicleData.regNumber} customerId={currentCustomer.id}/>
                            </Grid>
                            <AnimatePresence>
                                {exemptCheckboxChecked ? (
                                    <motion.div
                                        initial={{opacity: 0, height: 0}}
                                        animate={{opacity: 1, height: 'auto'}}
                                        exit={{opacity: 0, height: 0}}
                                        transition={{duration: 0.3}}>
                                        {billableVisit.exemption &&
                                        <Typography variant="subtitle1" gutterBottom mt={2}>
                                            {billableVisit.exemption?.changedBy && t("Exempt_By_Text",
                                                {
                                                    exemptionTime: new Date(billableVisit.exemption?.time).toLocaleDateString(navigator.language,
                                                    {
                                                        year: 'numeric',
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    }), 
                                                    exemptBy: billableVisit.exemption?.changedBy
                                                })
                                            }                                      
                                        </Typography>
                                        }
                                        <Typography variant="subtitle1" gutterBottom mt={2}>
                                            {t("Aktuellt Undantags ID:")}
                                            {billableVisit.exemption?.exemptionId ? billableVisit.exemption.exemptionId : t("Inget ID")}
                                        </Typography>
                                        <FormControl fullWidth>
                                            <InputLabel id="dropdown-label">{t("Orsak")}</InputLabel>
                                            <Select
                                                labelId="dropdown-label"
                                                name={t("Undanta besök med orsak")}
                                                label={t("Orsak")}
                                                value={formData.exemptionReason}
                                                onChange={handleSelectChange}
                                                error={validationErrors.exemptionReason.value}>
                                                <MenuItem
                                                    value={ExemptionType.PRIVATE_DISPOSAL}>{t(ExemptionType.PRIVATE_DISPOSAL)}</MenuItem>
                                                <MenuItem
                                                    value={ExemptionType.NO_DISPOSAL}>{t(ExemptionType.NO_DISPOSAL)}</MenuItem>
                                                <MenuItem
                                                    value={ExemptionType.DISPOSAL_FREE_OF_CHARGE}>{t(ExemptionType.DISPOSAL_FREE_OF_CHARGE)}</MenuItem>
                                                <MenuItem
                                                    value={ExemptionType.MANUAL_PAYMENT}>{t(ExemptionType.MANUAL_PAYMENT)}</MenuItem>
                                                <MenuItem
                                                    value={ExemptionType.OTHER}>{t(ExemptionType.OTHER)}</MenuItem>
                                            </Select>
                                            {validationErrors.exemptionReason.value && (
                                                <Typography variant="caption" color="error">
                                                    {validationErrors.exemptionReason.message}
                                                </Typography>
                                            )}
                                        </FormControl>
                                        <DialogActions style={{justifyContent: 'center'}}>
                                            <Button onClick={handleExemptChange} variant="contained"
                                                    sx={{backgroundColor: theme.palette.common.white}}>{t("Återinför fakturering")}</Button>
                                            <Button variant="contained"
                                                    onClick={toggleChangeHistoryModal}>{t("Se Ändringshistorik")}</Button>
                                        </DialogActions>
                                    </motion.div>
                                ) : (
                                    <DialogActions style={{justifyContent: 'left'}}>
                                        <Button onClick={handleExemptChange} variant="contained"
                                                color="warning">{t("Undanta_från_fakturering")}</Button>
                                    </DialogActions>
                                    )}
                            </AnimatePresence>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions sx={{m: 1}}>
                    {showingCurrentMonth && <Button  disabled={!compareData()} color="inherit" variant="outlined" type="submit">{t("Spara")}</Button>}
                    <Button variant="outlined" color="error" onClick={() => {resetFormData(); handleClose()}}>{t("Avbryt")}</Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog
                open={confirmationDialog.open}
                handleClose={() => confirmationDialog.handleClose(toggleHidden)}
                handleAgree={() => confirmationDialog.handleAgree(handleAgree)}
                title={t("Spara Besök")}
                subtitle={t("Följande uppgifter kommer att ändras")}
                content={infoData}
                errors={responseErrors ?? null}
                list={[]}
                active={false}/>
            <ChangeHistoryModal
                open={changeHistoryOpen}
                handleClose={toggleChangeHistoryModal}
                billableVisit={billableVisit}
            />
        </>
    );
};

export default UpdateBillableVisitModal;
