import {BillableVisit} from "../types/BillableVisit";
import {ExemptStats} from "../types/ExemptStats";
import {ApiResponse, ApiResponseSingle, extractEmbeddedResource, fetchData, getIdTokenHeader} from "./apiUtils";
import {isEmptyArray} from "../helpers/IsEmptyHelpers";
import {PayableStats} from "../types/PayableStats";
import { GridSortModel } from "@mui/x-data-grid";

export async function fetchBillableVisits(
    size: number, 
    page: number,
    sortModel: GridSortModel,
    customerId: number
) : Promise<ApiResponse<BillableVisit>> {
    const params = [
        `size=${size}`,
        `page=${page}`,
        `customerId=${customerId}`,
        sortModel.length > 0 ? `sort=${sortModel[0].field},${sortModel[0].sort}` : ''
    ];
    const apiUrl = `/api/billableVisits?${params.join('&')}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };

    const response = fetchData<BillableVisit[]>(
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<BillableVisit>(responseData, 'billableVisits')
    );

    return response as Promise<ApiResponse<BillableVisit>>;
}

/*ONLY FOR TESTING PURPOSES. REPLACE WITH ABOVE FOR PROD*/
export async function fetchBillableVisitsForTesting(size: number, customerId: number): Promise<ApiResponse<BillableVisit>> {
    const apiUrl = `/api/billableVisits?size=${size}&sort=vehicleData.sensorTime,desc`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };

    const response = fetchData<BillableVisit[]>(
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<BillableVisit>(responseData, 'billableVisits')
    );

    return response as Promise<ApiResponse<BillableVisit>>;
}

export async function fetchBillableVisitById(
    id:string,
    endpoint: string = 'billableVisits'
): Promise<ApiResponseSingle<BillableVisit>> {
    const apiUrl = `/api/${endpoint}/${id}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };

    const response = fetchData<BillableVisit>(apiUrl,options);
    return response as Promise<ApiResponseSingle<BillableVisit>>;
}

export async function fetchAdditionalBillableVisits(
    size: number, 
    customerId: number, 
    fromTimestamp: number
) : Promise<ApiResponse<BillableVisit>> {
    const apiUrl = `/api/billableVisits?size=${size}&customerId=${customerId}&sort=vehicleData.sensorTime,desc&vehicleData.sensorTime=${fromTimestamp}&vehicleData.sensorTime=0`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };

    const response = fetchData<BillableVisit[]>(
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<BillableVisit>(responseData, 'billableVisits')
    );

    return response as Promise<ApiResponse<BillableVisit>>;
}

/*ONLY FOR TESTING PURPOSES. REPLACE WITH ABOVE FOR PROD*/
export async function fetchAdditionalBillableVisitsForTesting(size: number, customerId: number, fromTimestamp: number): Promise<ApiResponse<BillableVisit>> {
    const apiUrl = `/api/billableVisits?size=${size}&sort=vehicleData.sensorTime,desc&vehicleData.sensorTime=${fromTimestamp}&vehicleData.sensorTime=0`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };

    const response = fetchData<BillableVisit[]>(
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<BillableVisit>(responseData, 'billableVisits')
    );

    return response as Promise<ApiResponse<BillableVisit>>;
}

export async function fetchExemptStats(regNumber: string, customerId: string, facilityids?: number[], from?: number | null, to?: number | null): Promise<ApiResponseSingle<ExemptStats>> {
    const apiUrl = `/api/billableVisits/search/stats?regnumber=${regNumber}&customerid=${customerId}&facilityids=${facilityids? facilityids : []}&from=${from? from : ''}&to=${to? to : ''}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };

    const response = fetchData<ExemptStats>(
        apiUrl,
        options
    );

    return response as Promise<ApiResponseSingle<ExemptStats>>;
}

export async function fetchPayableStats(customerId: string, month: string | null): Promise<ApiResponseSingle<PayableStats>> {
    const apiUrl = `api/billableVisits/search/payableStats?customerid=${customerId}&month=${month? month : ''}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };

    const response = fetchData<PayableStats>(
        apiUrl,
        options
    );

    return response as Promise<ApiResponseSingle<PayableStats>>;
}

export async function updateExempt(id: string, reason: any, comment?: any): Promise<ApiResponseSingle<BillableVisit>> {
    let apiUrl = `/api/billableVisits/search/exemptVisit?id=${id}&reason=${reason}`;
    if (comment !== undefined) {
        apiUrl += `&comment=${comment}`;
    }

    const options = {
        headers: getIdTokenHeader(),
        method: 'GET',
    };

    const response = fetchData<BillableVisit>(
        apiUrl,
        options
    );

    return response as Promise<ApiResponseSingle<BillableVisit>>;
}

export async function updateInvoice(id: string, reference?: string, comment?: string): Promise<ApiResponseSingle<BillableVisit>> {
    let apiUrl = `/api/billableVisits/search/invoiceVisit?id=${id}`;
    if (reference !== undefined) {
        apiUrl += `&reference=${reference}`;
    }
    if (comment !== undefined) {
        apiUrl += `&comment=${comment}`;
    }

    const options = {
        headers: getIdTokenHeader(),
        method: 'GET',
    };

    const response = fetchData<BillableVisit>(
        apiUrl,
        options
    );

    return response as Promise<ApiResponseSingle<BillableVisit>>;
}

export async function updateVisitComment(id: string, comment: string): Promise<ApiResponseSingle<BillableVisit>> {
    const apiUrl = `/api/billableVisits/search/commentVisit?id=${id}&comment=${comment}`;

    const options = {
        headers: getIdTokenHeader(),
        method: 'GET',
    };

    const response = fetchData<BillableVisit>(apiUrl, options);
    return response as Promise<ApiResponseSingle<BillableVisit>>;
}


export async function updateVisitReference(id: string, comment: string, reference: string): Promise<ApiResponseSingle<BillableVisit>> {
    const apiUrl = `/api/billableVisits/search/invoiceVisit?id=${id}&comment=${comment}&reference=${reference}`;

    const options = {
        headers: getIdTokenHeader(),
        method: 'GET',
    };

    const response = fetchData<BillableVisit>(apiUrl, options);
    return response as Promise<ApiResponseSingle<BillableVisit>>;
}

export async function updateForeignHandled(id: string, handled: boolean, comment?: string): Promise<ApiResponseSingle<BillableVisit>> {
    let apiUrl = `/api/billableVisits/search/handleForeignVisit?id=${id}&handled=${handled}`;
    if (comment !== undefined) {
        apiUrl += `&comment=${comment}`;
    }

    const options = {
        headers: getIdTokenHeader(),
        method: 'GET',
    };

    const response = fetchData<BillableVisit>(apiUrl, options);
    return response as Promise<ApiResponseSingle<BillableVisit>>;
}

export async function updateForeignVisit(id:string, handled:boolean, comment:string): Promise<ApiResponseSingle<BillableVisit>> {
    const apiUrl = `/api/billableVisits/search/handleForeignVisit?id=${id}&handled=${handled}&comment=${comment}`;

    const options = {
        headers: getIdTokenHeader(),
        method: 'GET',
    };

    const response = fetchData<BillableVisit>(
        apiUrl,
        options
    );

    return response as Promise<ApiResponseSingle<BillableVisit>>;
}

/**
 * Fetches billable visits based on search criteria.
 *
 * @param {number} size - Number of visits to fetch.
 * @param {number} page - Page number to fetch.
 * @param {number} customerId - Filters by the customerId field.
 * @param {string} search - The string to search for, which is tested against the union of fields vehicleData.regnumberClear (startsWith), vehicleData.owner (startsWith), and vehicleData.ownerName (contains).
 * @param {number[]} facilityIds - Filters by the facilityId field.
 * @param {number} from - Filters out all visits with vehicleData.sensorTime >= from.
 * @param {number} to - Filters out visits with vehicleData.sensorTime < to.
 * @param {string} typeFilters - Represents BillableVisitType and filters by the type field.
 * @param {string} customFilters - Represents a BillableVisits boolean attributes and filters by that attribute field.
 * @returns {Promise<ApiResponse<BillableVisit>>} A Promise that resolves to an ApiResponse containing billable visit data.
 */
export async function fetchBillableVisitsBySearchCriteria(
    size: number,
    page: number,
    sortModel: GridSortModel,
    customerId: number,
    search: string,
    facilityIds?: string[],
    from?: number,
    to?: number,
    typeFilters?: string[],
    customFilters?: string[],
    endpoint: string = 'billableVisits'

): Promise<ApiResponse<BillableVisit>> {
    const params: string[] = [`size=${size}&page=${page}&customerId=${customerId}&search=${search}`];

    if (facilityIds !== undefined && !isEmptyArray(facilityIds)) params.push(`facilityIds=${facilityIds.join(",")}`);
    if (from !== undefined && !isNaN(from)) params.push(`from=${from}`);
    if (to !== undefined && !isNaN(to)) params.push(`to=${to}`);
    if (typeFilters !== undefined && !isEmptyArray(typeFilters)) params.push(`types=${typeFilters.join(",")}`);
    if (customFilters !== undefined && !isEmptyArray(customFilters)) params.push(`${customFilters.join("&")}`);
    if (sortModel.length > 0) params.push(`sort=${sortModel[0].field},${sortModel[0].sort}`);
    const apiUrl = `/api/${endpoint}/search/searchRegnumberOrOwnernumberOrOwnerName?${params.join('&')}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };

    const response = fetchData<BillableVisit[]>(
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<BillableVisit>(responseData, endpoint)
    );

    return response as Promise<ApiResponse<BillableVisit>>;
}

export async function fetchSingleBillableVisit(
    visitId: string,
): Promise<ApiResponse<BillableVisit>> {

    const apiUrl = `/api/billableVisits?id=${visitId}`;

    const options = {
        headers: getIdTokenHeader(),
        method: "GET"
    };

    const response = fetchData<BillableVisit[]>(
        apiUrl,
        options,
        (responseData) => extractEmbeddedResource<BillableVisit>(responseData, 'billableVisits')
    );

    return response as Promise<ApiResponse<BillableVisit>>;
}
