import useTheme from "@mui/system/useTheme";
import { BillableVisit } from "../../../types/BillableVisit";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel, GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useCurrentCustomer } from "../../../contexts/CurrentCustomerContext";
import dayjs from "dayjs";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, LinearProgress, Paper, Switch, Typography, useMediaQuery } from "@mui/material";
import { CustomNoRowsOverlay } from "../../Shared/Tables/CustomNoRowsOverlay";
import UpdateBillableVisitModal from "../../Billing/Modals/UpdateBillableVisitModal";
import { isEmptyArray } from "../../../helpers/IsEmptyHelpers";
import { BillableVisitType } from "../../../types/enums/BillableVisitType";
import { formatOwner } from "../../../helpers/VisitFormatters";
import { boxStyle } from "../../Shared/Tables/listStyles";
import { getCurrentLanguage } from "../../../helpers/LanguageHelper";

interface VehicleHistoryModalProps {
    open: boolean,
    handleClose: any,
    vehicleVisitItems: BillableVisit[],
    loading: boolean,
    totalElements: number,
    paginationModel: GridPaginationModel,
    onPaginationModelChange: (newModel: GridPaginationModel) => void,
    sortModel: GridSortModel,
    onSortModelChange: (newSortModel: GridSortModel) => void,
    fromDate: dayjs.Dayjs | null,
    toDate: dayjs.Dayjs | null,
    showingCurrentMonth?: boolean,
    onChangeShowingCurrentMonth: (value: boolean) => void
}

const VehicleHistoryModal = (({
    vehicleVisitItems, 
    open, 
    handleClose, 
    loading, 
    totalElements, 
    paginationModel, 
    onPaginationModelChange,
    sortModel,
    onSortModelChange,
    fromDate,
    toDate,
    showingCurrentMonth=false, 
    onChangeShowingCurrentMonth

}: VehicleHistoryModalProps) => {
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const {currentCustomer} = useCurrentCustomer();
    const [selectedRow, setSelectedRow] = useState<BillableVisit | null>(null);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const today = dayjs().endOf('day');
    const firstDayOfMonth = today.startOf('month').startOf('day');


    useEffect(() => {
        setRows(vehicleVisitItems.map(generateRows));
    }, [vehicleVisitItems]);


    const getVisitType = (params: GridValueGetterParams) => {
        const visitType = params.value;
        let resultString = t(visitType);
        if(visitType === BillableVisitType.EXEMPT){
            const reason = params.row.exemptionReason;
            resultString += ' (' + t(reason) +')';
        }
        else if(visitType === BillableVisitType.PRE_REGISTERED && params.row.preRegistration){
            const status = params.row.preRegistration.status;
            resultString += ' (' +t(status) + ')';
        }
        return resultString;
    }
    const timeColumnFormatter = (params: GridValueFormatterParams):string => {
        return dayjs(params.value).locale(getCurrentLanguage(i18n.language)).format('D MMM HH:mm');
    };

    const columns: GridColDef[] = [
        {field: 'timeStamp', valueFormatter: timeColumnFormatter, headerName: t("Tid"), minWidth: 50, maxWidth: 125, flex: 1},
        {field: 'facility', headerName: t("Anlaggning"), sortable: false, minWidth: 50, maxWidth: 300, flex: 1, disableColumnMenu: true},
        {field: 'visitType', headerName: t("Visit_Type"), sortable: false, minWidth: 50, maxWidth: 300, flex: 1, disableColumnMenu: true, valueGetter: getVisitType},
        {field: 'comment', headerName: t("Kommentar"), minWidth: 50, maxWidth: 300, flex: 1, disableColumnMenu: true}
    ];

    const generateRows = (billableVisit: BillableVisit): any[] => {
        const {vehicleData} = billableVisit;
        const rows: any[] = [{
            id: billableVisit.id,
            timeStamp: dayjs(vehicleData.sensorTime).format('YYYY-MM-DD HH:mm'),
            facility: currentCustomer.facilities.find(facility => facility.id === billableVisit.facilityId)?.name ?? null,
            visitType: billableVisit.type,
            exemptionReason: billableVisit.exemption? billableVisit.exemption.reason : '',
            comment: billableVisit.comment,
            preRegistration: billableVisit.preRegistration
        }];

        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    };

    const [rows, setRows] = useState(() => vehicleVisitItems.map(generateRows));

    const handleRowClick = (params: any) => {
        const selectedVisit = vehicleVisitItems.find(visit => visit.id === params.row.id);
        if (selectedVisit) {
            setSelectedRow(selectedVisit);
            setIsUpdateModalOpen(true);
        }
    }

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const handleChangeShowingCurrentMonth = (e:React.ChangeEvent<HTMLInputElement>) => {
        onChangeShowingCurrentMonth(e.target.checked);
    };

    const calculateDisplayPeriod = () => {
        if(showingCurrentMonth){
            return `${firstDayOfMonth.format("YYYY-MM-DD")} - ${today.format('YYYY-MM-DD')}`;
        }
        else if(toDate?.isBefore(firstDayOfMonth)) {
            return `${fromDate?.format("YYYY-MM-DD")} - ${toDate.format("YYYY-MM-DD")}`;
        }
        else {
            return `${fromDate?.format("YYYY-MM-DD")} - ${firstDayOfMonth.subtract(1, 'day').format("YYYY-MM-DD")}`;
        }
    };

    return (
        <>
        {loading ? <LinearProgress/> :
        (
        <Dialog
            maxWidth="lg"
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {minwidth: '30vw'}
            }}
        >
            <DialogTitle align="center" variant="h5">{t("Fordon_Historik")}</DialogTitle>
            <DialogContent sx={{minWidth: '30vw', minHeight: '20vh'}}>
                <Grid container rowSpacing={3} columnSpacing={2} sx={{pt:1}}>
                    <Grid item xs={6}>
                    {vehicleVisitItems && vehicleVisitItems.length > 0 &&
                        <Typography align="center" variant="h6">{t("För_perioden")} {calculateDisplayPeriod()}</Typography>
                    }
                    {(!vehicleVisitItems || vehicleVisitItems.length < 1) &&
                        <Typography align="center" variant="h6">{t("Hittade_inte_besök_för_perioden")} {calculateDisplayPeriod()}</Typography>
                    }
                    </Grid>
                    <Grid item xs={6}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Switch
                                checked={showingCurrentMonth}
                                disabled={fromDate?.isSame(toDate, 'month') || (!fromDate?.isSame(today, 'month') && !toDate?.isSame(today, 'month'))}
                                onChange={handleChangeShowingCurrentMonth}
                            />} label={t("Visa_innevarande_månad")}
                        />
                    </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align="center" variant="h6">{t("Fordon")}:</Typography><Typography align="center" variant="h6">{!isEmptyArray(vehicleVisitItems) && vehicleVisitItems[0].vehicleData.regnumberClear}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align="center" variant="h6">{t("Företagsnamn")}:</Typography><Typography align="center" variant="h6">{!isEmptyArray(vehicleVisitItems) && 
                            formatOwner(vehicleVisitItems[0].vehicleData)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={boxStyle}>
                            <Paper>
                                <DataGrid
                                    sx={{height: '55vh', maxHeight: '70vh', width: '100%', '--DataGrid-overlayHeight': '300px'}}
                                    loading={loading}
                                    rows={rows}
                                    columns={columns}
                                    getRowId={(row: any) => row.id}
                                    paginationMode='server'
                                    rowCount={totalElements}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={onPaginationModelChange}
                                    sortModel={sortModel}
                                    onSortModelChange={onSortModelChange}
                                    sortingMode="server"
                                    sortingOrder={['desc', 'asc']}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    onRowClick={handleRowClick}
                                    hideFooterSelectedRowCount={true}
                                    hideFooter={false}
                                    disableRowSelectionOnClick={true}
                                    slots={{
                                        noRowsOverlay: CustomNoRowsOverlay,
                                    }}
                                />
                            </Paper>
                            <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                                <Grid container spacing={2}>
                                    <Grid item xs="auto" sx={{ marginLeft: { xs: 0, md: "auto"}}}>
                                        <UpdateBillableVisitModal
                                            open={isUpdateModalOpen}
                                            handleClose={() => setIsUpdateModalOpen(false)}
                                            setRowChanged={() => ''}
                                            billableVisit={selectedRow as BillableVisit}
                                            showingCurrentMonth={showingCurrentMonth}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{m: 1}}>
                <Button variant="outlined" color="error" onClick={() => {handleClose()}}>{t("Stäng")}</Button>
            </DialogActions>
        </Dialog>
        )
        }       
        </>
    );

});

export default VehicleHistoryModal;