import * as React from 'react';
import Box from "@mui/material/Box";
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import { useTranslation } from 'react-i18next';
import { Collapse, Link, List, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { DownloadLink } from '../Shared/DownloadLink';
import { useEffect, useState } from 'react';
import { useCurrentCustomer } from '../../contexts/CurrentCustomerContext';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { getReportMap } from '../../api/CustomerAPI';
import { isEmptyArray } from '../../helpers/IsEmptyHelpers';

const Reports = () => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [folderItems, setFolderItems] = useState<{foldername: string, items: {path: string, filename:string, nameOnDisk: string, type: string}[]}[]>([])
    const [fileItems, setFileItems] = useState<{path: string, filename:string, nameOnDisk: string, type:string}[]>([]);
    const {currentCustomer} = useCurrentCustomer();

    const [open, setOpen] = useState<string[]>([]);

    const handleOnClick = (name: string) => () => {
        const currentIndex = open.indexOf(name);
        const newOpen = [...open];

        if(currentIndex === -1) {
            newOpen.push(name);
        }
        else {
            newOpen.splice(currentIndex, 1);
        }
        setOpen(newOpen);
    }

    useEffect(() => {
        loadReports();
    }, [currentCustomer])

    const loadReports = async () => {
        const {data, errors} = await getReportMap(currentCustomer.id);
        if(isEmptyArray(errors)) {
            const currentMap = data;
            setFolderItems(currentMap.folders? currentMap.folders.map(folderItem => {return {foldername: folderItem.name, 
                items: folderItem.items.map(reportItem => {return {
                    type: reportItem.type, 
                    filename: reportItem.filename,
                    nameOnDisk: reportItem.nameOnDisk,
                    path: `${currentMap.folderId}/${folderItem.name}`
                }})
            }}) : []);
            setFileItems(currentMap.items? currentMap.items.map(reportItem => {return {
                type: reportItem.type,
                filename: reportItem.filename,
                nameOnDisk: reportItem.nameOnDisk,
                path: currentMap.folderId
            }}) : []);
        }
        else{
            setFolderItems([]);
            setFileItems([]);
        }
    };

    const hasExcelIcons = () => {
        return folderItems.some(folder => folder.items.some(item => item.type === 'xlsx')) ||
            fileItems.some(item => item.type === 'xslx');
    }

    return (
        <Box sx={{height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', gridGap: theme.spacing(2)}}>
            <Box sx={{ display: 'grid', gridGap: theme.spacing(2) }}>
                <Box sx={{ marginBottom: theme.spacing(2), maxWidth:{xs: '100%', sm: '95%'}}}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gridGap: theme.spacing(1) }}>
                        <ContentPasteIcon/>
                        <h2>{t("Rapporter")}</h2>
                    </Box>
                    <Box sx={{ marginBottom: theme.spacing(2), display: 'flex', gridGap: theme.spacing(1) }}>
                        <Typography>{t("Rapporter_Ledtext")}</Typography>
                        {folderItems.length > 0 ? (
                        <List>
                        {folderItems.map((folder) => (
                            <>
                            <ListItemButton key={folder.foldername} onClick={handleOnClick(folder.foldername)}>
                                <ListItemIcon>
                                    <FolderIcon/>
                                </ListItemIcon>
                                <ListItemText primary={folder.foldername}/>
                                {open.includes(folder.foldername)? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>
                            <Collapse in={open.includes(folder.foldername)} timeout='auto' unmountOnExit>
                                <List>
                                {folder.items.map((item) => (
                                    <DownloadLink key={item.path} item={item}/>
                                ))}
                                </List>
                            </Collapse>
                            </>
                        ))}
                        {fileItems.map((item) => (
                            <DownloadLink key={item.path} item={item}/>
                        ))}
                        </List>
                    ) :
                    (
                        <Typography>{t("Inga_rapporter")}</Typography>
                    )}
                    </Box>
                </Box>
            </Box>
            {hasExcelIcons() &&
            <Typography variant='caption'>
                <Link target="_blank" href="https://icons8.com/icon/85417/microsoft-excel">Excel</Link> icon by <Link target="_blank" href="https://icons8.com">Icons8</Link>
            </Typography>
            }
        </Box>
    );
}

export default Reports;